import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import MedicalEducationJumbotron from './assets/components/BookAppointmentJumbotron.js';
import MedicalEduWhatIs from './assets/components/BookAppointment.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';


// Importing CSS
import './assets/styles/header.css';  
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function BookAppointmentPage() {

  return (
    <div className="App">
      <Navbar />
      <MedicalEducationJumbotron />
      <MedicalEduWhatIs />
      <Footer />
      <Copyright />
    </div>
  );
}

export default BookAppointmentPage;
