import React from 'react';
import watchFile from '../icons/watchFile.jpeg';
import prodIn from '../icons/immunisations.webp';
import chc from '../icons/chc.webp';
// import ahs from '../icons/After hours.jpg';
import skinc from '../icons/skin care.jpg';
import teaching from '../icons/teaching.jpg';
// import elevateSuc from '../icons/elevateSuc.jpg';

function ServicesInfo() {
    return (
        <div className="information-sections">
            <div className="step-grid">
            <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={watchFile} ></img></div>
                <label className="question-working-sub">Medical Services</label>            
                <div className="content-answer">
                <strong>General medical checks including </strong>
                    <ul>
                        <li>Health screening and maintenance</li>
                        <li>Skin checks</li>
                        <li>Minor surgical procedures</li>
                        <li>Children's health services</li>
                        <li>Immunisations</li>
                        <li>Travel health and vaccinations</li>
                        <li>Men's health</li>
                        <li>Women's Health</li>
                        <li>Musculoskeletal</li>
                        <li>Chronic diseases and care coordination</li>
                        <li>Pathology</li>
                        <li>Pap smears</li>
                        <li>Home visits on prior booking</li>

                    </ul>                
                </div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={prodIn}></img></div>
                <label className="question-working-sub">Immunisations</label>
                <div className="content-answer">
                <p>A dedicated and experienced Registered Nurse is available to offer  immunisations for children. </p>
                <p>We also offer immunisation advice to  employees in the health sector, travel vaccinations and adult  vaccinations.</p> 
                </div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={chc}></img></div>
                <label className="question-working-sub">Child Health checks</label>
                <div className="content-answer">
                <p>We offer comprehensive health checks for children of all the ages,  including The Health Kids Check Program.<br></br> This Government subsidised  program includes various health indicators for your child.</p>
                </div>
            </div>
            {/* <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={ahs}></img></div>
                <label className="question-working-sub">After Hours Service</label>
                <div className="content-answer">
                <p>As a result of the growing demand for access to afterhours medical  services at Lane St. the Centre now has Extended Opening Hours until  11pm each night including Sundays and Public Holidays.</p>
                </div>
            </div> */}
            <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={skinc}></img></div>
                <label className="question-working-sub">Skin Cancer Checks</label>
                <div className="content-answer">
                <p>Dr. Madas and Dr. Alex are experienced general practitioners in offering  skin cancer checks and assessment of skin rashes, both in children and  adults. </p>
                </div>
            </div>
            <div className="step">
                <div className="logo-block"><img className='serviceslogo' src={teaching}></img></div>
                <label className="question-working-sub">Teaching Practice</label>
                <div className="content-answer">
                <p>The centre is a recognised teaching practice and hosts Medical students  from the University of Sydney and the University of Western Sydney as  well as training International Medical Graduates interested in the field  of General Practice. </p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ServicesInfo;
