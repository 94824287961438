import React from 'react';


function InformationSection() {
  return (
    <div className="information-sections">
        {/* <label className="question-working">Welcome to Lane Street Medical Center</label>
        <div className="content-answer"></div> */}
        
        <div className="step-grid">
            <div className="step">
                {/* <div className="logo-block"><img className="gridlogo" src={mikeIcon}></img></div>                 */}
                <label className="question-working-sub">Keeping Your Health in Mind</label>
                <div className="content-answer">
                  <p className='text'>Lane Street Medical Centre is a purpose built family general  practice that offers Bulk Billing Services without compromising the  quality of care. The Medical Centre was established in Oct 2012 with a  desire to provide the best possible medical care to patients, their  families, carers and community at large.</p>
                  </div>
            </div>
            
            <div className="step">
                {/* <div className="logo-block"><img className="gridlogo" src={dataAnalysisIcon}></img></div> */}
                <label className="question-working-sub">Australian Family Practice Offering the Best Possible Family Care</label>
                <div className="content-answer">
                <p className='text'>Lane Street Medical Centre is a purpose built family general  practice that offers Bulk Billing Services without compromising the  quality of care. The Medical Centre was established in Oct 2012 with a  desire to provide the best possible medical care to patients, their  families, carers and community at large. Our motto is to offer “quality  over quantity”. 
                The supportive staff at our Medical Centre try their  best to be customer friendly, empathetic and don’t mind going the extra  mile to assist patients and their families.Our quest for continuous learning and to constantly improve our  skills, while adhering to the basic tradition of family medicine, has  allowed the doctors to better respond to the needs of both patients and  the community. The Practice is gaining a fast reputation as a Centre of  excellence in providing GP services in the local community.</p>
                <p className='text'>We are transitioning to Mixed Billing from 24/3/23. Please ring reception on 02 9631 2708 for more information.</p>   
                </div>
            </div>
            
        </div>
    </div>
  );
}

export default InformationSection;
