import React from "react";
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import c1 from '../icons/c1.jpg';
import c2 from '../icons/c2.jpg';
import c3 from '../icons/c3.jpg';
import c4 from '../icons/c4.jpg';

const Images = [

    {
        id: 1,
        src: c1,
        alt: "Reception",
    },

    {
        id: 2,
        src: c2,
        alt: "Waiting area",
    },

    {
        id: 3,
        src: c3,
        alt: "Medical equipment",
    },

    {
        id: 4,
        src: c4,
        alt: "Image 4",
    },

];


export default function SimpleSlider() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className="container">
        <Slider {...settings}>
        {Images.map((item) => (
            <div key={item.id}>
              <img src={item.src} alt={item.alt} className="img" />
            </div>
          ))}
        </Slider>
      </div>
    )
        
}