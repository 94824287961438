import React from 'react';

function TermsAndConditionsSection() {
  return (
    <div className="impacts-sections">
        <label className="question-working-impacts">Terms and Conditions</label>
        
        <div className="step-grid-impact-tnc">
            <div className="step-impact">                          
                <label className="question-working-sub">Service Use</label>
                <div className="content-answer-tnc">MediSpeakAI is provided on an "as is" basis. Users agree to use this service responsibly and in accordance with all applicable laws and regulations.</div>
                
                <label className="question-working-sub">Limitations</label>
                <div className="content-answer-tnc">While MediSpeakAI is a powerful AI tool, it has its limitations. It should not replace professional medical advice or delay seeking medical treatment.</div>
           
                <label className="question-working-sub">Intellectual Property</label>
                <div className="content-answer-tnc">The software, content, visual interfaces, graphics, design, compilation, and all other elements of the MediSpeakAI service are owned by us. They are protected by copyright, trade dress, patent, and trademark laws, international conventions, and other relevant intellectual property and proprietary rights and laws.</div>
                
                <label className="question-working-sub">Changes to Terms</label>
                <div className="content-answer-tnc">MediSpeakAI reserves the right to change these terms periodically. Users will be notified of any changes in a timely manner.
                </div>
              
                <label className="question-working-sub">Liability</label>
                <div className="content-answer-tnc">To the extent permitted by law, MediSpeakAI will not be held responsible for any loss or damage resulting from the use of this service.
                </div>
            </div>        
        </div>
    </div>
  );
}

export default TermsAndConditionsSection;
