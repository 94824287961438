import React from 'react';
import keyStethImage from '../icons/keySteth.jpg';  // Import the image based on the given directory structure
import Slideshow from './SlideshowFunction';


function Jumbotron() {
  return (
    <div className="jumbotron">
        <div className="heading"><strong> Welcome to Lane Street Medical Center</strong>
            <h3 style={{paddingTop: '50px'}}><a style={{color:'white' }} href="tel:(02) 96312708">(02) 96312708</a></h3>
        </div>

    </div>
  );
}

export default Jumbotron;

