import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import InformationSection from './assets/components/InfoSection.js';
import Testimonials from './assets/components/Testimonials.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import ScrollToTop from './assets/components/scrollingFunction.js'
// Import all pages here
import AboutUsPage from './AboutUsPage.js'
import PrivacyPage from './PrivacyPolicyPage.js';  
import BookAppointment from './BookAppointmentPage.js'
import Sevices from './Services.js'
import ContactUs from './ContactUs.js'
import Slideshow from './assets/components/SlideshowFunction.js';
// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';



function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <div className="App">
        <Routes>                   
          <Route path="/privacy-policy" element={<PrivacyPage />} /> {/*Redirects like this*/}
          <Route path="/book-appointment" element={<BookAppointment />} /> {/*Redirects like this*/}
          <Route path="/about-us" element={<AboutUsPage />} /> {/*Redirects like this*/}
          <Route path="/services" element={<Sevices />} /> {/*Redirects like this*/}
          <Route path="/contact-us" element={<ContactUs />} /> {/*Redirects like this*/}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

function HomePage() {
  return (
    <>
      <Navbar />
      <Jumbotron />
      <Slideshow />
      <InformationSection />
      <Testimonials />
      <Footer />
      <Copyright />
    </>
  );
}

export default App;