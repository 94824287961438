import React from 'react';
import Slider from "react-slick";
import inputWindow from '../icons/Shivani_Nerella.jpg';
import guideWindow from '../icons/Shivam.jpg';
import summaryWindow from '../icons/pukar.png';

function Testimonials() {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     adaptiveHeight: true,
    //   };

    // return (
    //     <div className="testimonials">
    //         <label className="question-working">Testimonials</label>
    //         <div className="content-answer">Lets hear what our clients have to say about us !!</div>
    //         <div className="content-answer"></div>  

    //         <Slider {...settings}>  
    //         <div className="step-edu-specialist-main">
    //         <div className="slide-content-spec-testimonial">
    //           <div className="logo-block-spec"><img className="gridlogo-spec-testimonials" src={inputWindow} alt="Voice-Driven Clinical Documentation"></img></div>
    //           <div className="text-block-spec">              
    //             <p><h2>Dr Shivani Nerella,</h2>
    //             <h4>MBBS</h4>                
    //             As an international medical graduate gearing up for the AMC exam in Australia, MediSpeak AI has been transformative in my preparation. This user-friendly tool swiftly offers precise answers to complex medical questions, facilitating a deeper understanding of clinical guidelines and ethics.<br></br><br></br>Moreover, it helps navigate intricate patient cases, becoming an indispensable resource in my daily studies. Besides being a reservoir of timely medical developments, it serves as a strategic ally, bolstering my readiness for the exam and reinforcing my commitment to quality healthcare.
    //             Thank you, MediSpeak AI, for revolutionizing my AMC exam preparation and being an invaluable companion on my journey to becoming a certified medical professional in Australia.</p>
    //           </div>
    //         </div>
    //     </div>
    //     <div className="step-edu-specialist-main">
    //         <div className="slide-content-spec-testimonial">
    //           <div className="logo-block-spec"><img className="gridlogo-spec-testimonials" src={guideWindow} alt="Revolutionizing Clinical Decisions"></img></div>
    //           <div className="text-block-spec">              
    //             <p><h2>Dr Sivaram,</h2>
    //             <h4>MBBS</h4>                
    //             As an International Medical Graduate (IMG) with extensive experience in Australian healthcare settings, I must emphasize how MediSpeak AI has revolutionized our professional journey. This tool has served as a lifeline, enhancing our daily practice and expertise amidst the challenging Australian healthcare standards. Whether aiding in quick note-taking, navigating complex cases, or deciphering guidelines, it's like having an expert mentor readily available. <br></br><br></br>Notably, it has been indispensable in preparing for AMC exams, offering valuable insights and functionalities that ease the navigation of intricate clinical scenarios. It's an essential tool for continuous learning, keeping us abreast with the latest in medical knowledge.
    //             I highly recommend MediSpeak AI to fellow IMGs, a true game-changer in elevating healthcare practice in Australia.</p>
    //           </div>
    //         </div>
    //     </div>
    //     <div className="step-edu-specialist-main">
    //         <div className="slide-content-spec-testimonial">
    //           <div className="logo-block-spec"><img className="gridlogo-spec-testimonials" src={summaryWindow} alt="Optimizing Patient Communication"></img></div>
    //           <div className="text-block-spec">              
    //             <p><h2>Dr. Pukar Pandit,</h2>
    //             <h4>Sydney Adventist Hospital - Emergency Department</h4>                
    //             I am an International Medical Graduate and am studying to enhance my clinical skills so that I can work in the Australian Healthcare System. I have been using MediSpeak AI to interpret patients' chief complaints and presentations and it gives me extremely valid suggestions.
    //             It would accurately suggest me with possiblediagnosis and would suggest further history seeking questions. MediSpeak AI would easily recognise my voice and convert it into proper sentences and will generate suggestions that are highly accurate with the clinical scenario. <br></br><br></br>MediSpeak AI also provides me with the final summary of the treatment plan with medication and dose. MediSpeak AI has helped me immensely to improve my clinical interpretation skills.</p>
    //           </div>
    //         </div>
    //     </div>        
    //     </Slider>
    //     </div>
    // );
}

export default Testimonials;
