import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icons/Logo.jpg';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary navbar-styles">
        <div className="container-fluid">
            <Link to="/" className="navbar-brand">
                <img className="madas-health-logo" src={logo} alt="Lane Street Medical Center Logo" />
               {/* <span className="logo-label">Lane Street Medical Center</span> */}
            </Link>

            <button 
                className="navbar-toggler" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto"> 
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/book-appointment">Book Appointment</Link>
                    </li>                   
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/about-us">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/services">Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/contact-us">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;
