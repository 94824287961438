import React from 'react';
import CoA from '../icons/CoA.jpg'
import agpal from '../icons/AGPAL.png'
function OurFacilities() {
  return (
    <div className="information-sections">
            <div className="step-grid">
                <div className="step">
                  <div className="jumbotron-aboutUs">
                      <div> <label className="question-working-sub">Our Facililities </label>
                          <div className="content">
                            <div style={{fontWeight: 'bold'}}>
                          
                          <p className='text'>The Lane Street Medical Centre is a purpose built facility with a  state-of-the art facilities and equipment. The design and the fit-out  works were done by a reputable builder “Perfect Practice”. The practice  has four consulting rooms, a treatment room, pathology and a staff room.</p>
                          <p className='text'>The centre is fully furnished with quality equipment suitable to meet the needs of a family practice.
                                              It uses Douglas Hanly Moir Pathology. The centre uses the flexible,  user friendly and robust medical software called “Best Practice” for  clinical and appointment systems.</p>
                          <p className='text'>The Medical Centre has the following equipment to cater to the needs of patients:
                              <ul>
                                <li>ECG</li>
                                <li>Spirometry (lung testing equipment)</li>
                                <li>Defibrillator (portable automated external (AED)defibrillators designed to save lives)</li>
                                <li>Ultrasound therapy for various musculoskeletal conditions such as back pain, bursitis</li>
                                <li>WelchAllyn wall mounted enhanced diagnostic kit containing  macroview otoscope and panopthalmoscopy- improves diagnostic accuracy of  ear, throat and eye related conditions</li>
                                <li>Electrosurgical unit for minor surgical procedures</li>
                                <li>Advanced dermatoscopes for skin cancer checks</li>
                              </ul>
                          </p>

                            </div>
                          </div>
                        </div>
                  </div>
                </div>

                <div className="step">
                  <div className="jumbotron-aboutUs">
                      
                          <div className="content">
                            <div style={{fontWeight: 'bold'}}>
                                <img className='content-answer' src={agpal} alt='agpal'></img>
                                <img className='content-answer' src={CoA} alt='Certificate of Accreditation'></img>
                            </div>
                          </div>
                      
                  </div>
                </div>  
            </div>    
    </div>
  );
}

export default OurFacilities;
