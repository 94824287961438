import React from 'react';
import logoImage from '../icons/Logo.jpg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
        <div className="brand-footer">                          
            <div className="youtube-player-footerr">            
                <img 
                    width="560" 
                    height="315" 
                    src={logoImage} 
                    alt="YouTube video placeholder" 
                    title="YouTube video player" 
                    style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
                />
            </div>
        </div>
        
        {/* <div className="useful-links-footer">                
            <label className="question-working-sub">Useful Links</label>
            <div className="link-footer"><Link to="/privacy-policy" >Privacy Policy</Link></div>

        </div> */}
             <div className="contact-footer" style={{ color: 'black' }}>
              <div className="content-answer-footer">
                  <label className="Contact-values" style={{ color: 'black' }}><i className="fas fa-map-marker-alt"></i> <strong>Address</strong></label>
                  <h4 className="values" style={{ color: 'black' }}><strong>Suite 31, 15-17 Lane Street Wentworthville, New South Wales 2145, Australia</strong></h4>
                  <label className="Contact-values" style={{ color: 'black' }}><i className="fas fa-phone"></i><strong> Phone</strong></label>
                  <h4 className="values" style={{ color: 'black' }}><strong>+61 2 9631 2708</strong></h4>
              </div>
          </div>

    </div>    
  );
}

export default Footer;
