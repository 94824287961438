import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import JumbotronPrivacy from './assets/components/JumbotronPrivacy.js';
import PrivacyPolicyInfo from './assets/components/PrivacyPolicyJumbotron.js';
import TermsAndConditionsSection from './assets/components/TermsAndConditionsJumbotron.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function PrivacyPage() {
  return (
    <div className="App">
      <Navbar />
      <JumbotronPrivacy />
      <PrivacyPolicyInfo />
      <TermsAndConditionsSection />
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default PrivacyPage;
