import React from 'react';

function ContactUs() {
    return (
        <div className="information-sections">
        <div className="step-grid">
        <div className="step">
            
            <label className="question-working-sub">Reach us at:</label>            
            <div className="content-answer">
                <p>You are welcome to call the following numbers to schedule an appointment</p>
                <p>Landline Numbers are</p>
                 <ul>
                     <li><a className='hlink' href="tel:(02) 9631 2708">(02) 9631 2708</a></li>
                     <li><a className='hlink' href="tel:(02) 9631 5861">(02) 9631 5861</a></li>
                     <li><a className='hlink' href="tel:(02) 9631 7599">(02) 9631 7599</a></li>
                     <li><a className='hlink' href="tel:(02) 9631 8522">(02) 9631 8522</a></li>
                 </ul>
                     <p>Address: <a href="https:maps.app.goo.gl/GR3ZXfPdQn9Wci8GA">Suite 31, 15-17 Lane Street Wentworthville, New South Wales 2145, Australia </a> </p>
                     <p>You can also reach us via fax: <a className='hlink' href="tel:+61 2 9688 4500"> +61 2 9688 4500</a> <br></br>
                     Email us at <a href="mailto:info@lanestreetmedicalcentre.com.au">info@lanestreetmedicalcentre.com.au</a><br></br>
                     </p>                           
            </div>
        </div>
        
        <div className="step">
            
            <label className="question-working-sub" >Working Hours</label>
            <div className="content-answer">
            <p>Our doors are open during regular business hours, and we always appreciate visits from our valued customers.</p>
                    <table style={{marginLeft: '10px'}}>
                         <tr>
                             <td>Monday </td>
                             <td>:</td>
                             <td>07:30 AM - 11:00 PM </td>
                         </tr>
                         <tr>
                             <td>Tuesday </td>
                             <td>:</td>
                             <td>07:30 AM - 11:00 PM</td>
                            
                         </tr>
                         <tr>
                             <td>Wednesday</td>
                             <td>:</td>
                             <td>07:30 AM - 11:00 PM</td>
                            
                         </tr>

                         <tr>
                             <td>Thursday </td>
                             <td>:</td>
                             <td>07:30 AM - 11:00 PM</td>
                            
                         </tr>

                         <tr>
                             <td>Friday </td>
                             <td>:</td>
                             <td>07:30 AM - 11:00 PM</td>
                            
                         </tr>
                         <tr>
                             <td>Saturday</td>
                             <td>:</td>
                             <td>08:00 AM - 11:00 PM</td>
                            
                         </tr>
                         <tr>
                             <td>Sunday</td>
                             <td>:</td>
                             <td>08:00 AM - 05:00 PM</td>
                            
                         </tr>
                     </table> 

            </div>
        </div>
    </div>
</div>
        
        
    );
}



export default ContactUs;

