import React from 'react';
import Madas from '../icons/Madas.jpg'
import geeta from '../icons/Dr.GeetaKT.webp'
import noicon from '../icons/watchFile.jpeg'
import patel from '../icons/Dr Patel.jpg'
import paul from '../icons/Dr Paul.jpg'
import alex from '../icons/Dr Alex small image.jpg'
import maurice from '../icons/Dr. Maurica Matta.jpg'
import nisha from '../icons/nisha RN.jpg'
import raj from '../icons/RajSir.webp'


function AboutUsTeamDetails() {
    return (
        <div className="information-sections">
            <div className="step-grid">
                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 5rem'}} className='serviceslogo' src={Madas} ></img></div>
                    <label className="question-working-sub">Dr. Madas MBBS, MD. (GP)</label>            
                    <div className="content-answer">
                        <p className='text'>Dr. Eshwar Madas, MBBS, MD (India), MBS (NZ), FRACGP (Aus) Dr Madas  is an experienced general practitioner and is a fellow of Royal  Australian College of General Practitioner. He has experience of working  in Victoria, Darwin of Northern Territory and Blue Mountains prior to  setting up his own medical centre in Wentworthville (NSW). Dr Madas has  all round skills in general practice with a special interest in skin  checks, chronic disease management and men’s health. </p>
                        <p className='text'>Dr. Madas is a brilliant visionary who was keen to provide a remote  solution to modern-day medical challenges such as logistical barriers,  fragmented health services, and the Covid 19 pandemic. This led to the  ingenious creation of Madas Telehealth(MTH) also, which is a holistic online  healthcare provider that caters to patients seeking remote healthcare.  This is a novel and path-breaking system that offers a plethora of  services like Modern Medicine, Homeopathy, Ayurveda, and allied health  services. </p>
                        <p className='text'>Dr. Madas is a highly respected family physician who practises in  Western Sydney, Australia. He is a member of the Royal Australian  College of General Practitioners and a conjoint lecturer at the Western  Sydney School of Medicine. He is passionately involved in teaching  medical students from both the above-mentioned medical colleges, mentors  trainee physicians, and aids International medical graduates in  preparing for their Australian Medical Council examinations.  He is   involved in socio-cultural activities and provides health  education to the migrant population in Sydney. </p>                                
                    </div>
                </div>
                
                <div className="step">
                    <div className="logo-block"><img style={{ padding: '0 0.2rem' }} className='serviceslogo' src={geeta}></img></div>
                    <label className="question-working-sub">Dr. Geeta Krishna Tadimalla (Female GP)</label>
                    <div className="content-answer">
                        <p className='text'>Dr. Geeta Krishna Tadimalla has joined Lane Street Medical Centre in  Aug 2015. She has extensive experience in both general practice and  women’s health; both in India and the Middle East. She is registered  with Australian Health Practitioner Regulation Agency (APHRA) and is  accredited for providing shared care for pregnant women in Western  Sydney. </p>
                        <p className='text'>Dr. Geeta Krishna Tadimalla had acquired her primary qualification  from Lady Hardinge Medical College, New Delhi, India. She practised as a  general practitioner for many years in the town of Guntur of South  India. In addition, Dr Tadimalla had worked in a 400 bed secondary level  hospital in Saudi Arabia for 12 years as a registrar in Obstetrics and  Gynaecology under the guidance of fellows of the Royal college of  obstetrics and Gynaecology, London, UK. </p>
                        <p className='text'>Dr. Geeta Krishna Tadimalla is available at Lane Street Medical  Centre as an After Hours Doctor between 6pm-10pm week nights and  12pm-10pm on Saturdays and 9am-5pm on Sundays and public holidays. In  addition to general practice, her areas of specialist interest are in  managing antenatal cases, high risk pregnancies and family planning  matters. She possesses excellent skills and competency to carry out  procedures such as the insertion and removal of implanon, suturing  wounds, removal of skin cancers, etc. </p>
                        <p className='text'>Dr. Geeta Tadimalla speaks, In addition English, fluent Bengali, Telugu, Hindi and Urdu and can understand Arabic. </p>
                        <p className='text'>Dr. Tadimalla does not prescribe opioids or any drugs of dependence. She  will not issue backdated medical certificate and does not normally  provide medical certificates over the phone.</p> 
                    </div>
                </div>

                <div className="step">
                    {/* <div className="logo-block"><img className='serviceslogo' src={noicon}></img></div> */}
                    <label className="question-working-sub">Dr Sheela Kachwaha, MBBS,  DCH, FRACGP (Female GP)</label>
                    <div className="content-answer">
                        <ul>
                            <li>Women’s Health & Pregnancy Care</li>
                            <li>Children’s Health</li>
                            <li>Mental Health</li>
                            <li>Disability Care</li>
                            <li>Diabetes Care</li>
                        </ul>
                        <p className='text'>Dr. Sheela Kachwaha is an experienced medical practitioner and has been in General Practice for the last 20 years. She is a Fellow of the Royal Australian College of General Practitioners and also holds a Diploma in Child Health. </p>
                        <p className='text'>Dr. Sheela Kachwaha is highly qualified through her experience as a Senior Medical Officer in Westmead, Auburn and Blacktown Hospital, working in departments such as Obstetrics & Gynaecology, Renal, General Medicine & Surgery and the Emergency Department. She also has experience working in the Brain Injury Centre of Westmead Hospital and the Rehabilitation Department at St. Joseph Hospital in Auburn. </p>
                        <p className='text'>Through her 30 year career, Dr. Sheela Kachwaha has focused her expertise towards Women’s Health, which includes Shared Pregnancy Care with local hospitals, Disability Care and Mental Health support, enhanced by her completion of a Special Skill term in the Psychiatry Department of Westmead Hospital. She believes in providing professional and compassionate care for every patient and works towards that standard every day. </p>
                        <p className='text'>She is available at Lane Street Medical Centre every Tuesday and Thursday from 10 AM to 4 PM & on Sunday 9 AM to 4 PM.</p>
					</div>
				</div>

                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 3rem'}} className='serviceslogo' src={patel}></img></div>
                    <label className="question-working-sub">Dr. Bhadresh Patel (GP)</label>
                    <div className="content-answer">
					<p className='text'>Dr. Patel completed his GP training in western NSW and now works as a fellow GP. Dr Patel has also obtained a diploma in child health from Sydney University and has excellent knowledge and experience in children health. He is also a Shared Care GP with Westmead and Blacktown Hospitals for pregnancy care.</p>
                    <p className='text'>His Special interests are Ultrasound, Musculoskeletal injuries, Shared pregnancy care, Children health, Diabetes, Heart disease, Men's health, Chronic disease management, Minor skin surgeries.</p>
                    <p className='text'>Qualifications:  </p>
                    <ul>
                            <li>Fellow of Royal Australian College of General Practitioner (FRACGP)</li>
                            <li>Diploma Child Health (Australia)</li>
                            <li>Diploma Medical Ultrasound DMU (Australia)</li>
                            <li>Bachelor of Medicine & Bachelor of Surgery (MBBS)</li>        
                    </ul>

					</div>
				</div>	

                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 3rem'}} className='serviceslogo' src={paul}></img></div>
                    <label className="question-working-sub">Dr. Paul Singh (GP)</label>
                    <div className="content-answer">
                    <p className='text'>
                    Dr Paul Singh is the latest addition to the team at Lane Street Medical Centre. He has a great deal of experience in the Wentworthville region having worked here since 1995. He graduated with MBBS (Honours) from the University of NSW in 1991. He spent rotations at Westmead, Blacktown, Nepean, Mt Druitt, Camden, and has rural experience in Dorrigo and Orange base Hospital. He has experience in Casualty, O&G, anaesthetics and General Medicine. He passed his Fellowship of the Royal Australian College of General Practitioners in 1996.</p>
                    <p className='text'> He has an interest in preventive care and a firm belief in lifestyle correction to boost quality longevity. A true believer in work life balance, he enjoys camping, hiking, driving and spending time with his family. He is known to enjoy in moderation of course, a glass of wine. He endeavours to continue to serve the Wentworthville district with outstanding medical care in the tradition of his late father Joginder.
                    </p>
                    </div>
                </div>        

                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 3rem'}} className='serviceslogo' src={alex}></img></div>
                    <label className="question-working-sub">Dr. Alagendran Kanapathippillai (GP)</label>
                    <div className="content-answer">
                    <p className='text'> Dr. Alagendran Kanapathippillai is an Australian-based health  professional. 
                    Alagendran is trained as a GP (General Practitioner) and  practices in Lane Street Medical Centre, Wentworthville. 
                    </p>
                    </div>
                </div>    

                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 2rem'}} className='serviceslogo' src={nisha}></img></div>
                    <label className="question-working-sub">Ms. Nisha Bandari (RN, Physician Assistant)</label>
                    <div className="content-answer">
                    <p className='text'> Ms. Nisha is a Full-time Registered Nurse and a Physician Assistant at our general practice. She has graduated from University of Sydney 2019 with her Master's degree. She has been a part of our medical practice for over the past 5 years and her skill and work contribution still going on. Nisha has immense knowledge and practice in immunisations, dressings, wound care, suturing, antenatal care, postpartum care, elderly assessments. This comes from her previous medical background MBBS graduate 2014 India, and onshore practical experience. She plays a crucial role in providing compassionate care and ensuring the smooth operation of our medical services. </p>
                    <p className='text'> Nisha is fluent in English, Hindi and Telugu languages. She is certainly a team player and leader when needed her contribution. She also extends her work at reception, for which she was awarded All-Rounder award at our clinic 2020. As a part of clinical team, She is trained listen attentively to patients concerns, thoroughly assess condition, and collaborate with our doctors to develop personalized care plans tailored to your unique needs. Nisha has always embraced the values of empathy, integrity, and professionalism, upholding the highest standards in her clinical practice. At our GP practice, we are proud to have such dedicated registered nurses who consistently strive for excellence in delivering patient-centred care.
                    
                    </p>

                    </div>
                </div>    

                <div className="step">
                    <div className="logo-block"><img style={{padding: '0 2rem'}} className='serviceslogo' src={raj}></img></div>
                    <label className="question-working-sub">Mr. Rajeshwar Reddy, Healthcare IT Specialist</label>
                    <div className="content-answer">
                    <p className='text'> Rajeshwar is a highly experienced IT Specialist, boasting a remarkable 20 years of expertise in the IT and  data science fields. He holds an impressive educational background,  including a MicroMasters in Statistics and Data Science from the  Massachusetts Institute of Technology, a Post Graduation in Data Science  from the Indian Institute of Technology Guwahati, a Bachelor of  Engineering in Computer Technology from Kavikulguru Institute of  Technology and Science at Nagpur University in India, and a Computer  Engineering Diploma from S.N.M Polytechnic Khammam, India.     </p>    
                    
                    </div>

                </div>   

                <div className="step">
                    {/* <div className="logo-block"><img style={{padding: '0 1rem'}} className='serviceslogo' src={raj}></img></div> */}
                    <label className="question-working-sub">Anne Laguthaas, Practice Manager</label>
                    <div className="content-answer">
                    <p className='text'>Our dedicated and experienced Practice Manager Anne brings in a wealth of expertise to our team, having worked in the healthcare industry for over a decade. She is highly skilled in managing the day-to-day operations of a busy medical centre and is committed to delivering the highest standard of care to our patients.</p>
                    <p className='text'>Anne has a proven track record of improving operational efficiency, streamlining processes and implementing best practices. Her ability to effectively communicate and collaborate with doctors, nurses and support staff ensures that our medical centre runs smoothly and efficiently.</p>
                    <p className='text'>Anne is passionate about patient care and takes pride in providing a friendly and welcoming environment for all patients. She is always available to answer any questions or concerns patients may have and strives to ensure that every patient receives the care they need in a timely and compassionate manner. </p>
                    
                    </div>
                </div>    

            </div>
        </div>    
    );
}

export default AboutUsTeamDetails;




