import React from 'react';
import keyStethImage from '../icons/team.webp'

function AboutUsTeam() {
  return (
    <div className="jumbotron-ourTeam">   
        <div className="heading"><strong>Additional Services</strong>
            <div className="content">
              <h3 style={{textAlign:'left', color: 'black'}}>Providing Second Opinions </h3>
            <p className='text'>The Centre is attracting patients on a regular basis for second  opinions regarding the diagnosis of their conditions or treatment  options given to them. Asking a new doctor for a second opinion does not  necessarily mean a patient is disloyal to his or her existing GP. Nor  does it mean that the patient is moving to a new practice. It could  simply mean, they are looking for a second set of eyes to look over  their medical illness to ensure that their condition is diagnosed as  accurately as possible and to receive the best treatment available in a  timely manner.  Our doctors are very happy to offer 2nd opinions.</p>    
            <p className='text' style={{fontWeight:'bold'}}>If an individual is interested to come for a 2nd opinion, they should  ask the receptionist for a longer appointment (30 minutes) and should  bring along with them (if available) a health summary, copies of  pathology and radiology tests done prior.</p>
            </div>
        </div>  
                              
    </div>    
  );
}

export default AboutUsTeam;
