import React from 'react';
import keyStethImage from '../icons/Logo.jpg'

function AboutUsJumbo() {
  return (
    <div className="jumbotron-aboutUs">
        <div className="heading"><strong>Our Clinic</strong>
            <div className="content">
              <div style={{fontWeight: 'bold'}}>
            
            <p className='text'> Lane Street Medical Centre is a purpose built family general practice  that offers Mixed Billing Services. The Centre has 4 consulting rooms, a  treatment room and a pathology collection room. The Medical Centre is  located approximately 350 meters to Wentworthville railway station.  Wentworthville (postcode 2145) is a rapidly growing suburb of Sydney,  New South Wales of Australia. It is located around 23kms from the Sydney  CBD.</p>
            <p className='text'>The Medical Centre has 7 full time doctors including 2 female doctors.  Allied health professionals that work at the Centre include: a Practice  Registered Nurse, an Accredited Practising Dietician, a Psychologist, an Exercise  Physiologist and an Audiologist (hearing testing).</p>
            <p className='text'>The Lane Street Medical Centre serves a population of diverse ethnic  backgrounds. The Medical Centre is aimed at providing best possible  family care. The Centre offers a comprehensive range of community based, co-ordinated and customer friendly GP services to patients, their  families, carers and the community at large. The services provided  include general health checks, skin cancer checks, children and  immunisation related services, chronic disease management,  musculoskeletal and women’s and men’s health services.</p>

              </div>
            </div>
        </div>
        
    </div>
  );
}

export default AboutUsJumbo;
