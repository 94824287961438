import React from 'react';

function PrivacyPolicyInfo() {
  return (
    <div className="information-sections">
        <label className="question-working">Purpose</label>
        <div className="content-answer">At MediSpeakAI, we prioritise the privacy and security of our users. This policy reflects our adherence to Australian health and data protection standards, including the Australian Privacy Principles (APPs) and the Privacy Act 1988 (Cth).</div>
        
        <div className="step-grid-priv-pol">
            <div className="step-priv-pol">              
                <label className="question-working-sub">Information Collection</label>
                <div className="content-answer"><strong>Scope:</strong> MediSpeakAI gathers essential data for optimal functionality. This may encompass device details, usage patterns, IP addresses, and browser specifics. To maintain the highest standards of health data protection, we explicitly avoid collecting or retaining any personally identifiable patient data.</div>
                <div className="content-answer"><strong>Alignment with Standards:</strong> Our data collection aligns with the Health Privacy Principles outlined in the Health Records and Information Privacy Act 2002 (NSW), ensuring utmost respect for health-related privacy.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Information Use</label>
                <div className="content-answer"><strong>Enhancement:</strong> The information allows us to refine our services, perform in-depth usage analysis, and elevate the user experience.</div>
                <div className="content-answer"><strong>Data Integrity:</strong> As per the Australian Digital Health Agency's framework, we strive to ensure the accuracy and relevance of the information we gather.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Information Sharing</label>
                <div className="content-answer"><strong>Commitment:</strong> MediSpeakAI firmly stands against selling or sharing user data with third parties.</div>
                <div className="content-answer"><strong>Legality:</strong> Any potential data disclosure will strictly align with the Australian Privacy Principles and the Privacy Act 1988, underscoring our commitment to national standards.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Information Security</label>
                <div className="content-answer"><strong>Protection:</strong> We pledge to safeguard your data diligently. MediSpeakAI employs state-of-the-art cloud security solutions (Microsoft Azure) to ensure data integrity and confidentiality.</div>
                <div className="content-answer"><strong>Compliance with Standards:</strong> Our data protection strategies are in line with the Australian Government Information Security Manual and Standards Australia's best practices.</div>
                <div className="content-answer"></div>
                
                <label className="question-working-sub">Policy Updates</label>
                <div className="content-answer"><strong>Amendments:</strong> While we reserve the right to modify this policy occasionally, we will prioritize keeping our users informed. Any alterations will be communicated promptly and transparently.</div>
                <div className="content-answer"><strong>Thank you for trusting MediSpeakAI. Your privacy remains paramount to us.</strong></div>
            </div>
            
        </div>
    </div>
  );
}

export default PrivacyPolicyInfo;
