import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import ServicesJumbotron from './assets/components/ServicesJumbotron.js';
import AssistcareInfo from './assets/components/Servicesinfo.js';

import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function Services() {
  return (
    <div className="App">
      <Navbar />
      <ServicesJumbotron />
      <AssistcareInfo />
      {/* <SignupComponent /> */}
      <Footer />
      <Copyright />
    </div>
  );
}

export default Services;
