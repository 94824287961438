import React from 'react';

function SignupComponent() {
  // Handler to open the form link in a new tab
  const handleButtonClick = () => {
    window.open('https://forms.office.com/r/GLgjmp5Kr9', '_blank');
  };

  return (
    <div className="signup-comp">
        <label className="question-working-signup">Join our journey towards impactful healthcare with MediSpeak.AI</label>
        <div className="join-button">
            <button className="signup-button" onClick={handleButtonClick}>SIGN UP FOR A FREE TRIAL AND DEMO</button>
        </div>
    </div>
  );
}

export default SignupComponent;
