import React from 'react';



import drMadas from '../icons/Madas.jpg';
import drGeeta from '../icons/Dr.GeetaKT.webp';
import drPaul from '../icons/Dr Paul.jpg';
import drPatel from '../icons/Dr Patel.jpg';
import drAlex from '../icons/Dr Alex small image.jpg';

import noicon from '../icons/infouse.jpg';

import { Link } from 'react-router-dom';

function MedicalEduWhatIs() {
    return (
        <div className="testimonials">
            <label className="question-working">  </label>
            
            <div className="step-grid">
                <div className="step-edu">
                    <div className="left-section">
                        <img src={drMadas} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Dr. Eshwar Madas, General Practitioner</label>
                        <div><strong>MBBS, MD (India), MBS (NZ), FRACGP (Aus) </strong></div>
                        <div style={{padding : '2rem'}}> 
                            <button className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-eshwar-madas" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={drGeeta} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Dr. Geeta Krishna Tadimalla, <br></br>Female General Practitioner</label>
                        <div className='text'>Dr. Geeta had acquired her primary qualification from Lady Hardinge Medical College, New Delhi, India. She has extensive experience in both general practice and  women’s health.

                        </div>
                        <div style={{padding : '2rem'}}> 
                            <button  className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-geeta-krishna-tadimalla" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={noicon} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                    <label className="question-working-sub">Dr. Sheela Kachwaha, <br></br>Female General Practitioner</label>
                        <div className='text'>Dr. Sheela is an experienced medical practitioner and has been in General Practice for the last 20 years. She is a Fellow of the Royal Australian College of General Practitioners and also holds a Diploma in Child Health.

                        </div>
                        <div style={{padding : '2rem'}}> 
                            <button  className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-sheela-kachwaha" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={drPatel} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                    <label className="question-working-sub">Dr. Bhadresh Patel, <br></br>Male General Practitioner</label>
                        <div className='text'>Dr. Patel completed his GP training in western NSW and now works as a fellow GP. Dr Patel has also obtained a diploma in child health from Sydney University and has excellent knowledge and experience in children health.

                        </div>
                        <div style={{padding : '2rem'}}> 
                            <button  className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-bhadresh-patel-1" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={drPaul} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                    <label className="question-working-sub">Dr. Paul Singh, <br></br>Male General Practitioner</label>
                        <div className='text'>Dr. Paul Singh is the latest addition to the team at Lane Street Medical Centre. He has a great deal of experience in the Wentworthville region having worked here since 1995. He graduated with MBBS (Honours) from the University of NSW in 1991.

                        </div>
                        <div style={{padding : '2rem'}}> 
                            <button  className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-paul-singh-3" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div> 

                <div className="step-edu">
                    <div className="left-section">
                        <img src={drAlex} alt="Description of Image" />
                    </div>
                    <div className="right-section">
                    <label className="question-working-sub">Dr. Alagendran Kanapathippillai, <br></br>Male General Practitioner</label>
                        <div className='text'>Dr. Alagendran Kanapathippillai is an Australian-based health  professional. Alagendran is trained as a GP (General Practitioner) and  practices in Lane Street Medical Centre, Wentworthville. 

                        </div>
                        <div style={{padding : '2rem'}}> 
                            <button  className='appointmentButton'>
                            <Link to ="https://www.hotdoc.com.au/medical-centres/wentworthville-NSW-2145/lane-street-medical-centre/doctors/dr-alagendran-kanapathippillai-dr-alex" > Book Appointment</Link>
                            </button>
                            
                        </div>
                    </div>
                </div>

              
            </div>
        </div>
    );
}

export default MedicalEduWhatIs;
