import React from 'react';

function AppointmentJumbotron() {
  return (
    <div className="jumbotron-appointment">
        <div className='heading-appointment'> <h1> <strong> Doctors </strong>
        </h1> 
        </div>
        
    </div>
  );
}

export default AppointmentJumbotron;

