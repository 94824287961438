import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import AboutUsJumbo from './assets/components/AboutUsJumbo.js';
import AboutUsTeam from './assets/components/AboutUsTeam.js';
import AboutUsTeamDetails from './assets/components/AboutUsTeamDetails.js';
import OurFacilities from './assets/components/OurFacilities.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function AboutUsPage() {
  return (
    <div className="App">
      <Navbar />
      <Jumbotron />
      <AboutUsJumbo />
      <AboutUsTeamDetails />
      <AboutUsTeam />
      <OurFacilities />
      <Footer />
      <Copyright />
    </div>
  );
}

export default AboutUsPage;
